<template>
	<div :class="['order-info', type ? `order-info--${type}` : '']">
		<div class="order-info__header">
			<h1
				v-if="number"
				class="order-info__number">
				# {{ number }}
			</h1>

			<h2 class="order-info__title">
				{{ order.event }}
				<span
					v-if="order.attributes?.agent_fio"
					class="order-info__title-sub">
					{{ order.attributes.agent_fio }}
				</span>
			</h2>
		</div>

		<div class="order-info__content">
			<p
				v-if="order.attributes?.culture"
				class="order-info__product order-info__item">
				{{ order.attributes.culture }}
			</p>

			<p
				v-if="order.attributes?.farmer"
				class="order-info__supplier-name order-info__item">
				<span>Поставщик </span> {{ order.attributes.farmer }}
			</p>

			<p
				v-if="order.attributes?.warehouse"
				class="order-info__item">
				Склад: {{ order.attributes.warehouse }}
			</p>

			<div
				v-if="order.attributes?.fca || order.attributes?.fca_nds"
				class="order-info__item">
				Цена с места (FCA), ₽/тн: <span class="order-info__price-value">{{ order.attributes.fca }}</span>

				без НДС, <span class="order-info__price-value">{{ order.attributes.fca_nds }}</span> с НДС
			</div>

			<div
				v-if="order.attributes?.cpt || order.attributes?.cpt_nds"
				class="order-info__item">
				Цена покупателя (СРТ), ₽/тн: <span class="order-info__price-value">{{ order.attributes.cpt }}</span>

				без НДС, <span class="order-info__price-value">{{ order.attributes.cpt_nds }}</span> с НДС
			</div>

			<p
				v-if="order.attributes?.volume"
				class="order-info__item">
				Объём: <span class="order-info__price-value">{{ order.attributes.volume }} тн</span>
			</p>

			<p
				v-if="order.attributes?.client"
				class="order-info__item">
				Покупатель: {{ order.attributes.client }}
			</p>

			<p
				v-if="order.attributes?.route || order.attributes?.tarif"
				class="order-info__item">
				Транспортировка: {{ order.attributes.route }} км /{{ order.attributes.tarif }} ₽/тн
			</p>
		</div>

		<div
			v-if="order.attributes?.qualities"
			class="order-info__specs">
			<div
				class="order-info__spec-item"
				v-for="(quality, index) in qualities"
				:key="index">
				{{ quality }}
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	const props = withDefaults(
		defineProps<{
			order: IEvent | IMessage;
			number?: number;
			type?: TOfferTypes;
		}>(),
		{
			number: 0,
			type: "",
		},
	);

	const qualities = props.order.attributes?.qualities?.split(";");
</script>

<style lang="scss" scoped>
	@import "order-info";
</style>
